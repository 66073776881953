import {
  MultiSelectFilter,
  FilterValue,
  StringArrayFilterValue,
} from "../../types";
import {
  createFilterValueFromMultiSelectOption,
  getSelectedFilterValue,
  updatedFilterValueFromValues,
} from "../../utils";
import { PillMultiSelect } from "../PillSelect";

interface InputMultiSelectPillDesktopProps {
  filter: MultiSelectFilter;
  filterValues: FilterValue[];
  defaultValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const InputMultiSelectPillDesktop = ({
  filter,
  filterValues,
  defaultValues,
  setFilterValues,
}: InputMultiSelectPillDesktopProps) => {
  const { id } = filter;
  const selected = getSelectedFilterValue(
    id,
    filterValues,
    defaultValues
  ) as StringArrayFilterValue;

  return (
    <PillMultiSelect
      filter={filter}
      filterValue={selected}
      selected={selected}
      onChange={(option, isSelected) => {
        const value = createFilterValueFromMultiSelectOption(
          filter.id,
          option,
          selected,
          isSelected
        );
        setFilterValues(
          updatedFilterValueFromValues(filterValues, filter, value)
        );
      }}
    />
  );
};
