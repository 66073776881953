import React from "react";
import { ProfileSectionItem } from "../../../types/profileSectionType";
import { ProfileSectionListView } from "./ProfileSectionListView";
import { ProfileSectionHeader } from "./ProfileSectionHeader";
import {
  useCountryId,
  useEmail,
  usePhone,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { useMyStore } from "@coworker/app/src/core/auth/useLoggedInUser";
import SpeechBubbleIconPath from "@coworker/apprestructured/src/shared/wrappers/FixaSSRIcon/paths/SpeechBubbleIconPath";
import LightbulbWithRaysIconPath from "../../../../shared/wrappers/FixaSSRIcon/paths/LightbulbWithRaysIconPath";
import { useStoreContactsEnabled } from "@coworker/app/src/hooks/useStoreContacts";
import { useTranslation } from "@coworker/locales";
import { useMyTeam } from "@coworker/app/src/core/hooks/useStoreTeams";
import versionJson from "@coworker/app/src/core/version.json";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { languageMapper } from "../../../helpers/profileLanguageHelper";
import { useCountries } from "@coworker/app/src/core/hooks/useCountries";
import { isChineseEnvironment } from "@coworker/reusable";

export const ProfileSections: React.FC = () => {
  const { t } = useTranslation();
  const isStoreContactsEnabled = useStoreContactsEnabled();
  const email = useEmail();
  const phone = usePhone();
  const store = useMyStore();
  const storePrimaryLocale = store?.primaryLocale;
  const [userLanguage] = useUserPreference("language", storePrimaryLocale);
  const myTeam = useMyTeam();
  const isChina = isChineseEnvironment();
  const languageName = languageMapper(userLanguage).name;
  const myCountryId = useCountryId();
  const { data: countries } = useCountries();
  const myCountry = countries?.find(
    (country) => country.isoCode === myCountryId
  );

  const profileSections: ProfileSectionItem[] = [
    {
      id: "team",
      title: "teamString",
      description: myTeam?.name ?? "",
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/team",
      isStoreEnvironment: true,
      loading: !myTeam,
    },
    {
      id: "store",
      title: "adminStoreFilter",
      description: `${store?.storeId} - ${store?.name}`,
      isNavigational: true,
      isTitleBold: true,
      isStoreEnvironment: true,
      loading: !store,
    },
    {
      id: "country",
      title: "adminCountryFilter",
      description: isChina ? "China" : myCountry?.name ?? "",
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/team",
      isStoreEnvironment: true,
      loading: !isChina && myCountry === undefined,
    },
    {
      id: "updateLanguage",
      title: "languageString",
      description: languageName,
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/language",
      loading: !languageName.length,
    },
  ];

  const storeContactsSection: ProfileSectionItem[] = [
    {
      id: "updateStoreContact",
      title: phone ? "updateContactString" : "addContactString",
      description: phone ?? t("noneString"),
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/phone",
    },
    {
      id: "storeContacts",
      title: "storeString",
      description: "Find all store contacts",
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/contacts",
    },
  ];

  const emailSection: ProfileSectionItem = {
    id: "email",
    title: "emailString",
    description: email,
    isNavigational: false,
    isTitleBold: true,
  };

  const allProfileSections: ProfileSectionItem[] = isStoreContactsEnabled
    ? [...profileSections, ...storeContactsSection, emailSection]
    : [...profileSections, emailSection];

  const helpSection: ProfileSectionItem[] = [
    {
      id: "appSupport",
      title: "supportString",
      isNavigational: true,
      navigationUrl: "/support",
      isTitleBold: false,
    },
    {
      id: "privacyPolicy",
      title: "privacyString",
      isNavigational: true,
      navigationUrl: "/privacy",
      isTitleBold: false,
    },
  {
    id:"training",
    title:"trainingString",
    isNavigational:true,
    isTitleBold:false,
    ssrIcon:LightbulbWithRaysIconPath,
    link:"https://ikea.csod.com/ui/lms-learner-search/search?pageNumber=1&query=fixa",
  },
    {
      id: "feedback",
      title: "giveFeedbackString",
      isNavigational: true,
      isTitleBold: false,
      ssrIcon: SpeechBubbleIconPath,
      link: "https://engage.cloud.microsoft/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIzMzE5OTkzOTU4NCJ9/all",
    },
    {
      id: "version",
      title: "versionString",
      description: versionJson.version,
      isNavigational: false,
      isTitleBold: true,
    },
  ];

  return (
    <>
      <ProfileSectionHeader title={t("profileString")} />
      <ProfileSectionListView id="profile" sectionsList={allProfileSections} />
      <ProfileSectionHeader title={t("supportString")} />
      <ProfileSectionListView id="help" sectionsList={helpSection} />
    </>
  );
};
