// Types and interfaces connected to the React UI components to show filter.
// If the React UI components for filtering are used we will get a common look
// and behavior.
// There is a need to define filters and filter-values in some way since the
// same information is used in two or three different components. Representing
// filters as (dropdown) pills in the filter row. Show the same filters in
// the filter modal. And sometimes show pills for applied filters.

// What React component to use
export enum InputType {
  Select = "Select",
  MultiSelect = "MultiSelect",
  Search = "Search",
  Checkbox = "Checkbox",
  RadioButton = "RadioButton",
}

// Is the React component for filtering or sorting
export enum FilterType {
  Filter = "Filter",
  Sort = "Sort",
}

// Base information about the filter
interface BaseFilter {
  inputType: InputType;
  filterType: FilterType;
  id: string;
  title: string;
  enabled?: boolean;
  defaultValue: FilterValue;
  valueToSet?: string;
  idToUpdate?: string;
}

// Different filters
interface SelectFilterBase extends BaseFilter {
  options: SelectItem[];
  showSearch?: boolean;
}

export interface SelectFilter extends SelectFilterBase {
  inputType: InputType.Select;
}

export interface MultiSelectFilter extends SelectFilterBase {
  inputType: InputType.MultiSelect;
}

export interface SearchFilter extends BaseFilter {
  inputType: InputType.Search;
}
export interface CheckboxFilter extends BaseFilter {
  inputType: InputType.Checkbox;
}
export interface RadioButtonFilter extends BaseFilter {
  inputType: InputType.RadioButton;
}

export type Filter =
  | SelectFilter
  | MultiSelectFilter
  | SearchFilter
  | CheckboxFilter
  | RadioButtonFilter;

// Select option
export interface SelectBase {
  title: string;
}

export interface SelectCategory extends SelectBase {}

export interface SelectOptionBase extends SelectBase {
  // This filter depends on filter specified in this property. This property is
  // set in the filter hook and copied to filter values when an option is
  // selected.
  dependsOnFilterId?: string | undefined;

  // This filter is dependent of this filter specified by this property. This is
  // used to open/expand child/sub accordions when selected in parent filter.
  // Should be set in filter hooks.
  dependentOfFilterId?: string | undefined;
}

export interface SelectOptionString extends SelectOptionBase {
  value: string;
}

// String array values for options makes it possible to handle select all in an
// multi select (like any other option). Similar to select all for select
// filters. It also makes it possible to enable or disable any set of values
// which could be used to select all of a type of options.
export interface SelectOptionStringArray extends SelectOptionBase {
  value: string[];
}

export type SelectOption = SelectOptionString | SelectOptionStringArray;
export type SelectItem = SelectCategory | SelectOption;

// Filter values that can be used in filters
interface BaseFilterValue {
  filterId: string;

  // Depending filter whose values also must be cleared when this filter is
  // changed or cleared. This property should be transferred from option
  // values when option is selected.
  dependsOnFilterId?: string | undefined;

  // Dependent filter used to trigger opening of dependent filters when this
  // value is selected. This property should be transferred from option
  // values when option is selected.
  dependentOfFilterId?: string | undefined;
}

export interface NullFilterValue extends BaseFilterValue {
  value: null;
}

export interface StringFilterValue extends BaseFilterValue {
  value: string;
}

export interface NumberFilterValue extends BaseFilterValue {
  value: number;
}

export interface StringArrayFilterValue extends BaseFilterValue {
  value: string[];
}
export interface BooleanFilterValue extends BaseFilterValue {
  value: boolean;
}

export type FilterValue =
  | NullFilterValue
  | StringFilterValue
  | NumberFilterValue
  | StringArrayFilterValue
  | BooleanFilterValue;
