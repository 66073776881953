import { ApplyFilter, Filter, FilterValue, FilterType } from "../../types";

// Filter and sort data in memory.
export function useMemoryFilteredData<T>(
  items: T[] | undefined,
  filters: ApplyFilter<Filter, T>[],
  filterValues: FilterValue[]
) {
  if (!items) {
    return items;
  }
  const filterFilters = filters.filter(
    ({ filter: { filterType } }) => filterType === FilterType.Filter
  );
  const sortFilters = filters.filter(
    ({ filter: { filterType } }) => filterType === FilterType.Sort
  );
  const filterCompare = filterFilters.map((filter) => {
    const value = filterValues.find(
      ({ filterId }) => filter.filter.id === filterId
    );
    return { ...value!, filterCompare: filter.filterCompare };
  });
  const sortCompare = sortFilters.map((filter) => {
    const value = filterValues.find(
      ({ filterId }) => filter.filter.id === filterId
    );
    return { ...value!, sortCompare: filter.sortCompare };
  });

  const filteredItems = items.filter((item) => {
    for (const compare of filterCompare) {
      if (
        compare.filterCompare &&
        compare &&
        !compare.filterCompare(item, compare)
      ) {
        return false;
      }
    }
    return true;
  });

  const sortedItems = filteredItems.sort((item1, item2) => {
    const compare = sortCompare[0];
    if (compare && compare.sortCompare) {
      return compare.sortCompare(item1, item2, compare);
    }
    return 0;
  });

  return sortedItems;
}
