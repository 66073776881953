import {
  Filter,
  FilterType,
  FilterValue,
  InputType,
  StringArrayFilterValue,
  StringFilterValue,
} from "../../types";
import { useTranslation } from "@coworker/locales";
import AppliedFilterRowCss from "./AppliedFilterRow.module.css";
import { FilterRowScrollPanel } from "../FilterRowScrollPanel";
import { AppliedSelectPill } from "./AppliedSelectPill";
import { AppliedMultiSelectPill } from "./AppliedMultiSelectPill";
import { AppliedCheckboxPill } from "./AppliedCheckboxPill";

interface AppliedFilterRowProps {
  filters: Filter[];
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

export const AppliedFilterRow = ({
  filters,
  filterValues,
  setFilterValues,
}: AppliedFilterRowProps) => {
  const { t } = useTranslation();

  const appliedFilterCss = [AppliedFilterRowCss["applied-filter-container"]];
  if (filterValues.length === 0) {
    appliedFilterCss.push(AppliedFilterRowCss["applied-filter-display-none"]);
  }

  if (filterValues.length === 1 && filterValues[0]?.filterId === "sortBy") {
    return null;
  }

  return (
    <div className={appliedFilterCss.join(" ")}>
      <span className={AppliedFilterRowCss["applied-filter-text"]}>
        {t("appliedFiltersString")}
      </span>
      <FilterRowScrollPanel>
        {filters
          .filter(
            ({ enabled, filterType }) =>
              enabled === true && filterType !== FilterType.Sort
          )
          .map((filter) => {
            const filterValue = filterValues.find(
              ({ filterId }) => filterId === filter.id
            );

            switch (filter.inputType) {
              case InputType.Select: {
                return (
                  <AppliedSelectPill
                    key={filter.id}
                    filter={filter}
                    filterValue={filterValue as StringFilterValue}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                  />
                );
              }
              case InputType.MultiSelect: {
                return (
                  <AppliedMultiSelectPill
                    key={filter.id}
                    filter={filter}
                    filterValue={filterValue as StringArrayFilterValue}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                  />
                );
              }
              case InputType.Checkbox: {
                return (
                  <AppliedCheckboxPill
                    filter={filter}
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    filterValue={!!filterValue?.value}
                  />
                );
              }
              default:
                return null;
            }
          })}
      </FilterRowScrollPanel>
    </div>
  );
};
