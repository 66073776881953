import { useTranslation } from "@coworker/locales";
import { useCustomAllSelectOption } from "../../shared/filters/utils";
import { FilterType, InputType } from "../../shared/filters/types";
import { LOCATION_ID } from "./useLocationFilter";

export const TASK_TYPE_ID = "taskType";

export function useTaskTypeFilter() {
  const { t } = useTranslation();
  const defaultValue = { filterId: TASK_TYPE_ID, value: "" };

  return useCustomAllSelectOption(
    {
      inputType: InputType.Select,
      filterType: FilterType.Filter,
      id: TASK_TYPE_ID,
      title: t("task_typeString"),
      options: [
        {
          value: "pickAndRefill",
          title: t("addonTitleString"),
          dependentOfFilterId: LOCATION_ID,
        },
        {
          value: "refill",
          title: t("refillString"),
        },
        {
          value: "productIssue",
          title: t("taskTypeTitleProductIssue"),
        },
        {
          value: "PQR",
          title: t("productQualityReportString"),
        },
        {
          value: "roomSettings",
          title: t("mediaMaintenanceString"),
        },
        {
          value: "testbuy",
          title: t("testBuyFollowUpString"),
        },
        {
          value: "mfaq",
          title: t("mfaqFollowUpTitle"),
        },
        {
          value: "custom",
          title: t("customTasksString"),
        },
        {
          value: "main",
          title: t("mainScheduledTasksString"),
        },
        {
          value: "planned",
          title: t("scheduledTasksString"),
        },
      ],
      enabled: true,
      defaultValue,
    },
    t("allTaskTypesString")
  );
}
