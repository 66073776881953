import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { revealFromBelow } from "../../helpers/animationHelper";
import { Link } from "../Link";
import { isRDTDevice } from "@coworker/reusable";

const OverflowMenu = styled('div')`
  position: fixed;
  z-index: var(--z-popupmenu);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  max-height: 80%;
`;

const ActionsContainer = styled('div')`
  overflow-y: auto;
`;

const OverflowBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  z-index: var(--z-popupmenu);
`;

const PosedOverflowMenu = revealFromBelow(OverflowMenu);

const PosedOverflowBackground = motion(OverflowBackground);

const OverFlowItem = styled('div')`
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 90px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  ${({ icon }) => icon && `column-gap: 24px;padding-left:28px;`};
  ${({ icon }) =>
    icon ? "justify-content: left" : "justify-content:space-between"};
  color: var(--grey900);
  ${({ enabled }) =>
    enabled ? "" : `color: var(--grey200); pointer-events: none;`};
  border-bottom: 1px solid var(--grey150);
  background: white;
`;

export const PopupMenu = ({ onClose, actions, testId }) => {
  const [mounted, setMounted] = React.useState(true);
  const callbackRef = React.useRef(null);
  const withUnmountDelay = React.useCallback((fn) => {
    setMounted(false);
    callbackRef.current = fn;
  }, []);
  const isRDT = isRDTDevice();
console.log("PopupMenu", actions, mounted);

  return (
    <AnimatePresence
      initial={!isRDT}
      onExitComplete={() => callbackRef.current && callbackRef.current()}
    >
      {mounted && (
        <PosedOverflowBackground
          data-testid="backdrop"
          initial={{ opacity: 0.8 }}
          animate={{ opacity: 0.8 }}
          exit={{ opacity: 0 }}
          onClick={() => withUnmountDelay(onClose)}
        />
      )}

      {mounted && (
        <PosedOverflowMenu
          as={motion.div}
          data-testid={testId}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ActionsContainer data-testid="ActionsContainer">
            {actions.map((action, i) => {
              if (!action || action.isHidden) return null;

              if (action.onClick && typeof action.onClick !== "function") {
                throw Error("'onClick' property must be of type function.");
              }

              if (action.to && typeof action.to !== "string") {
                throw Error("'to' property must be of type string.");
              }

              const key = action.enum || action.id || `popupMenuAction-${i}`; // Use a unique property or fallback to index

              return (
                <Link
                  testId={"popMenuOption-" + i}
                  key={key}
                  onClick={
                    action.onClick && !action.isDisabled ? action.onClick : null
                  }
                  to={action.to && !action.isDisabled && action.to}
                >
                  <OverFlowItem
                    enabled={!action.isDisabled}
                    {...action.props}
                    data-testid={action.enum}
                    icon={action.icon}
                  >
                    {action.icon}
                    {typeof action.title == "function"
                      ? action.title()
                      : action.title}
                  </OverFlowItem>
                </Link>
              );
            })}
          </ActionsContainer>
        </PosedOverflowMenu>
      )}
    </AnimatePresence>
  );
};
