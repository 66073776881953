import { useState } from "react";
import { FilterRowDesktop } from "./FilterRowDesktop";
import { AppliedFilterRow } from "./AppliedFilterRow";
import { FilterModal } from "./FilterModal";
import { Filter, FilterValue } from "../types";
import { getDefaultFilterValue } from "../utils/filter";

interface FilterPanelDesktopProps {
  useFiltersFactory: (
    filterValues: FilterValue[],
    setFilterValues: (values: FilterValue[]) => void
  ) => Filter[];
  filterValues: FilterValue[];
  setFilterValues: (filterValues: FilterValue[]) => void;
}

// Main component to view filters.
export const FilterPanelDesktop = ({
  useFiltersFactory,
  filterValues,
  setFilterValues,
}: FilterPanelDesktopProps) => {
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const filters = useFiltersFactory(filterValues, setFilterValues);
  const defaultValues = getDefaultFilterValue(filters);

  return (
    <>
      <FilterRowDesktop
        filters={filters}
        filterValues={filterValues}
        defaultValues={defaultValues}
        setFilterValues={setFilterValues}
        setFilterModalIsOpen={setFilterModalIsOpen}
      />
      <AppliedFilterRow
        filters={filters}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
      <FilterModal
        visible={filterModalIsOpen}
        useFiltersFactory={useFiltersFactory}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        defaultValues={defaultValues}
        onClose={() => setFilterModalIsOpen(false)}
      />
    </>
  );
};
