import {
  Filter,
  FilterValue,
  StringArrayFilterValue,
  StringFilterValue,
} from "../../types";

export function isStringArrayFilterValue(
  value: FilterValue
): value is StringArrayFilterValue {
  return Array.isArray(value.value);
}
export function isStringFilterValue(
  value: FilterValue
): value is StringFilterValue {
  return typeof value.value === "string";
}

export function getSelectedFilterValue(
  filterId: string,
  filterValues: FilterValue[],
  defaultValues: FilterValue[]
): FilterValue {
  const filterValue = filterValues.find(
    (filterValue) => filterId === filterValue.filterId
  );
  const defaultValue = defaultValues.find(
    (filterValue) => filterId === filterValue.filterId
  );
  if (filterValue && typeof filterValue?.value === typeof defaultValue?.value) {
    return filterValue;
  } else if (defaultValue) {
    return defaultValue;
  } else {
    return {
      filterId: filterId,
      value: null,
    };
  }
}

export function getDefaultFilterValue(filters: Filter[]) {
  return filters.map(({ defaultValue }) => defaultValue);
}

export function findValueIsInFilterValues(
  currentValues: FilterValue[],
  filterId: string
) {
  return currentValues.find(
    (currentValue) => filterId === currentValue.filterId
  );
}

export function valueIsInFilterValues(
  currentValues: FilterValue[],
  filterId: string
) {
  return !!findValueIsInFilterValues(currentValues, filterId);
}

export function updatedFilterValueFromValues(
  currentValues: FilterValue[],
  filter: Filter,
  newValue: FilterValue
) {
  if (filter.id !== newValue.filterId) {
    console.log("Internal error. filter.id and newFilter.filterId must match.");
    return currentValues;
  }
  // Do not save default values
  if (filterValueAreTheSame(newValue, filter.defaultValue)) {
    return removeFilterValueFromValues(currentValues, filter.id);
  }

  return [
    ...currentValues.filter(
      (currentValue) =>
        filter.id !== currentValue.filterId &&
        filter.id !== currentValue.dependsOnFilterId
    ),
    newValue,
  ];
}

export function removeFilterValueFromValues(
  currentValues: FilterValue[],
  filterId: string
) {
  if (!valueIsInFilterValues(currentValues, filterId)) {
    return currentValues;
  } else {
    return [
      ...currentValues.filter(
        (currentValue) =>
          filterId !== currentValue.filterId &&
          filterId !== currentValue.dependsOnFilterId
      ),
    ];
  }
}
export function stringArrayAreTheSame(values1: string[], values2: string[]) {
  if (values1.length !== values2.length) {
    return false;
  } else {
    values1.sort((value1, value2) => value1.localeCompare(value2));
    values2.sort((value1, value2) => value1.localeCompare(value2));
    for (let idx = 0; idx < values1.length; idx++) {
      if (values1[idx] !== values2[idx]) {
        return false;
      }
    }
    return true;
  }
}

export function filterValueAreTheSame(
  values1: FilterValue,
  values2: FilterValue
) {
  if (values1.filterId !== values2.filterId) {
    return false;
  } else {
    if (
      isStringArrayFilterValue(values1) &&
      isStringArrayFilterValue(values2)
    ) {
      return stringArrayAreTheSame(values1.value, values2.value);
    } else if (values1.value !== values2.value) {
      return false;
    }
  }
  return true;
}

export function filterValuesAreTheSame(
  values1: FilterValue[],
  values2: FilterValue[]
) {
  if (values1.length !== values2.length) {
    return false;
  } else {
    values1.sort((value1, value2) =>
      value1.filterId.localeCompare(value2.filterId)
    );
    values2.sort((value1, value2) =>
      value1.filterId.localeCompare(value2.filterId)
    );
    for (let idx = 0; idx < values1.length; idx++) {
      if (!filterValueAreTheSame(values1[idx]!, values2[idx]!)) {
        return false;
      }
    }
    return true;
  }
}
